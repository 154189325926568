<template>
  <b-card>
      <!-- form -->
      <b-form class="mt-2">
        <b-row class="mt-2">
          <b-col cols="12" md="4" lg="4">
            <label class="mb-1">{{ $t("Date") }}</label>
            <b-form-input id="date" v-model="date" />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <label class="mb-1">{{ $t("Location") }}</label>
            <b-form-input id="location" v-model="location" />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <label class="mb-1">{{ $t("Samples") }}</label>
            <b-form-input id="samples" v-model="samples" />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12" md="4" lg="4">
            <label class="mb-1">{{ $t("Research") }}</label>
            <b-form-textarea
              id="research"
              v-model="research"
              @change="onChangeSource()"
              class="source-input"
              placeholder="research"
              rows="2"
              no-auto-shrink
            />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <label class="mb-1">{{ $t("yDna") }}</label>
            <b-form-input id="yDna" v-model="yDna" />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <label class="mb-1">{{ $t("mtDna") }}</label>
            <b-form-input id="mtDna" v-model="mtDna" />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="12" md="12" lg="12">
            <label class="mb-1">{{ $t("Description") }}</label>
            <b-form-textarea
              id="desciption1"
              v-model="desciption1"
              @change="onChangeSource()"
              class="source-input"
              placeholder="desciption1"
              rows="2"
              no-auto-shrink
            />
          </b-col>
        </b-row>
        
        <b-row class="mt-2">
          <b-col cols="12">
            <save-button 
              :onClickSaveAndClose="saveItem.bind(this, true)"
              :onClickSave="saveItem.bind(this, false)"
              />
          </b-col>
        </b-row>

      </b-form>
  </b-card>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { getDataVersion } from '@/store/ancestry-data/utils'

import SaveButton from '@/components/SaveButton.vue';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'

const { mapFields } = createHelpers({
  getterType: 'ancientProxManagement/getAncientField',
  mutationType: 'ancientProxManagement/updateAncientField',
});

export default {
  components: {
    SaveButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  methods: {
    saveItem(isClose) {
      const query = this.$store.getters['ancientProxManagement/getAncientForm'];
      if (query.dataVersion !== getDataVersion(this.$store)) {
        AlertService.warning(this, 'Data version does not match selected version');
        return;
      }

      this.$store.dispatch('ancientProxManagement/saveAncientProx', query)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })

            if (isClose) {
              this.$router.go(-1)
            } else {
              this.$store.commit('ancientProxManagement/setAncientField', response.result);
              this.$router.push({ name: 'manage-ancient-prox-save', params: { id: response.result.id } });
            }
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
  },
  computed: {
    ...mapFields([
      'desciption1',
      'desciption2',
      'desciption3',
      'research',
      'date',
      'location',
      'samples',
      'yDna',
      'mtDna',
    ]),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.source-input {
    box-sizing: border-box;
    margin: 0px;
    outline: 0px;
    padding: 1em;
    height: calc(100% - 3em);
    min-height: 3em;
    width: 100%;
    overflow: auto;
    color: inherit;
    font-size: 0.7em;
    white-space: pre;
}
</style>
